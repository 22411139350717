<template>
  <div class="set">
    <header>
      <div class="left">
        <img src="../img/板块图标 - 副本_slices/logo.png" alt="" />
      </div>
      <ul class="right">
        <li @click="back">
          <img src="../img/上课/返回.png" alt="" />
          <span>返回</span>
        </li>
        <li>
          <el-dropdown
            trigger="click"
            placement="bottom-start"
            style="height: 50/96in; display: flex"
          >
            <div
              class="el-dropdown-link"
              style="display: flex; align-items: center; height: 30px"
            >
              <img src="../img/上课/帮助 (5).png" alt="" />
              帮助
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <span @click="tohelp">系统通知</span>
              </el-dropdown-item>
              <el-dropdown-item>
                <span @click="tohelp2">系统介绍</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li @click="toset">
          <img src="../img/上课/设置 (6).png" alt="" />
          <span>设置</span>
        </li>
        <li @click="out">
          <img src="../img/上课/结束.png" alt="" />
          <span>结束课程</span>
        </li>
      </ul>
    </header>
    <main>
      <div class="box">
        <div class="title">账号设置</div>
        <div class="inputname">姓名</div>
        <el-input
          placeholder="请输入姓名"
          suffix-icon="el-icon-edit-outline"
          v-model="user"
        >
        </el-input>
        <div class="inputname">账号</div>
        <el-input v-model="phone" :value="phone" readonly> </el-input>
        <div class="inputname">邮箱</div>
        <el-input
          placeholder="请输入账号"
          suffix-icon="el-icon-edit-outline"
          v-model="yx"
        >
        </el-input>
        <div class="inputname">密码</div>
        <el-input
          placeholder="请输入密码"
          suffix-icon="el-icon-edit-outline"
          type="password"
          v-model="pwd"
        >
        </el-input>
        <div class="inputname">性别</div>
        <el-input
          placeholder="请输入"
          suffix-icon="el-icon-edit-outline"
          password
          v-model="sex"
        >
        </el-input>
        <div class="inputname">年龄</div>
        <el-input
          placeholder="请输入年龄"
          suffix-icon="el-icon-edit-outline"
          password
          v-model="age"
        >
        </el-input>
        <div class="btn">
          <div>保存</div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import * as util from "@/unit/network";
export default {
  data() {
    return {
      user: "",
      nums: "",
      phone: "",
      yx: "",
      pwd: "",
      sex: "",
      age: "",
    };
  },
  mounted() {
    util.post("/teacherInfo", {}, (res) => {
      console.log(res);
      this.phone = res.msg.phone;
    });
  },
  methods: {
    back() {
      this.$router.push("/main");
    },
    out() {
      util.post(
        "/courseEnd",
        {
          md_str: localStorage.getItem("md"),
        },
        (res) => {
          if (res.code == 1) {
            this.$router.push("/main/starts");
          }
        }
      );
    },
    tohelp() {
      this.$router.push({
        path: "/help",
        query: {
          id: 1,
        },
      });
    },
    tohelp2() {
      this.$router.push({
        path: "/help",
        query: {
          id: 2,
        },
      });
    },
    toset() {
      this.$router.push("/set");
    },
    abc() {
      console.log(111);
    },
  },
};
</script>
<style lang="less" scoped>
.set {
  header {
    height: 60/96in;
    background-color: #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.35rem /* 35/100 */ 0 0.2rem /* 56/100 */;
    border-bottom: 1px solid #cdcdcd;
    .left {
      display: flex;
      align-items: center;
      img {
        height: 36/96in;
        margin-right: 8px;
      }
    }
    .right {
      display: flex;
      li {
        margin-left: 0.33rem /* 33/100 */;
        cursor: pointer;
        display: flex;
        align-items: center;
        img {
          height: 22/96in;
          margin-right: 7px;
        }
      }
    }
  }
  main {
    display: flex;
    justify-content: center;
    background-color: #eff0f2;
    // height: calc(100vh - 0.625in);
    padding: 31px 0 22px;
    .box {
      width: 729px;
      // height: 100%;
      padding: 0 179px;
      background-color: #fff;
      // display: flex;
      // align-items: center;
      // flex-direction: column;
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #131313;
        margin: 74px 0 57px;
        text-align: center;
      }
      .inputname {
        font-size: 14px;
        font-weight: bold;
        color: #131313;
        margin-bottom: 12px;
        margin-top: 36px;
      }
      /deep/.el-input {
        border: 1px solid #d5d5d5;
        border-radius: 2px;
      }
      .btn {
        margin-top: 130px;
        display: flex;
        justify-content: center;
        margin-bottom: 78px;
        div {
          width: 100px;
          height: 36px;
          background: #1c5eff;
          border-radius: 5px;
          font-size: 14px;
          color: #fff;
          text-align: center;
          line-height: 36px;
        }
      }
    }
  }
}
</style>